import React, { useState } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);
    if (captchaToken) {
      // Create an object including all fields, including `subject`
      const object = {
        name: formData.name,
        email: formData.email,
        subject: formData.subject,
        message: formData.message,
        access_key: "a615c6da-9274-404d-a884-bcf3125d387a",
      };

      try {
        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(object),
        }).then((res) => res.json());

        if (res.success) {
          setFormData({ name: "", email: "", subject: "", message: "" });
          setSuccessMessage("Your message has been sent successfully!");
          setTimeout(() => setSuccessMessage(null), 5000); // Clear message after 5 seconds
        } else {
          setError("Failed to send message. Please try again.");
        }
      } catch (error) {
        setError("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      setError("Captcha verification failed. Please try again.");
      setLoading(false);
    }
  };
  const handleVerificationSuccess = (token, ekey) => {
    setCaptchaToken(token);
    // console.log("Captcha Success", token, ekey);
  };

  return (
    <form id="contact-form" onSubmit={onSubmit}>
      <div className="row gx-3 gy-4">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">Your Name</label>
            <input
              name="name"
              placeholder="Name *"
              className="form-control"
              type="text"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label">Your Email</label>
            <input
              name="email"
              placeholder="Email *"
              className="form-control"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="col-12">
          <div className="form-group">
            <label className="form-label">Subject</label>
            <input
              name="subject"
              placeholder="Subject *"
              className="form-control"
              type="text"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="form-label">Your message</label>
            <textarea
              name="message"
              placeholder="Your message *"
              rows={4}
              className="form-control"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="col-md-12">
          <HCaptcha
            sitekey="2eb0da6a-2968-48f6-a75c-90a8a80c1298"
            onVerify={(token, ekey) => handleVerificationSuccess(token, ekey)}
            theme={"dark"}
          />
        </div>
        <div className="col-md-12">
          {successMessage && <p className="text-success">{successMessage}</p>}
          {error && <p className="text-danger">{error}</p>}
          <div className="send">
            <button
              className={`px-btn w-100 ${loading ? "disabled" : ""}`}
              type="submit"
            >
              {loading ? "Sending..." : "Send Message"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
