import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p className="m-0 text-center">
          © 2024 Copyright All Right Reserved | Illz pOp
        </p>
      </div>
    </footer>
  );
}
